import axios from "axios";

const apiUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/api/admin"
    : process.env.REACT_APP_API_URL;

const soapClient = async (url, xml) => {
  try {
    // Send the request to your backend's proxy endpoint instead of directly to the SOAP service
    const response = await axios.post(`${apiUrl}/proxy/soap`, {
      url: url, // The external SOAP service URL
      xml: xml, // The SOAP XML data to send
    });

    // Parse the SOAP response and extract necessary data
    return response.data;
  } catch (error) {
    console.error("SOAP request failed:", error.response.data);
    throw error;
  }
};

export default soapClient;
