import { fetchUtils } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";

const apiUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/api/admin"
    : process.env.REACT_APP_API_URL;

const httpClient = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  // Extract the token from localStorage
  const token = JSON.parse(localStorage.getItem("auth"))?.token;
  if (token) {
    options.headers.set("Authorization", `Bearer ${token}`);
  }

  let response;

  try {
    response = await fetchUtils.fetchJson(url, options);
  } catch (error) {
    if (error.status === 401) {
      // Access token has expired or is invalid
      const refreshResponse = await fetch(`${apiUrl}/refresh_token`, {
        method: "POST",
        credentials: "include", // Important: Sends the HTTP-only cookie
      });

      if (refreshResponse.ok) {
        const json = await refreshResponse.json();
        const newToken = json.accessToken;

        // Update localStorage with the new access token
        const auth = JSON.parse(localStorage.getItem("auth"));
        auth.token = newToken;
        localStorage.setItem("auth", JSON.stringify(auth));

        // Retry the original request with the new token
        options.headers.set("Authorization", `Bearer ${newToken}`);
        response = await fetchUtils.fetchJson(url, options);
      } else {
        // Handle the case where the refresh token is also expired or invalid
        localStorage.removeItem("auth");
        return Promise.reject(new Error("Unauthorized"));
      }
    } else {
      // If the error is not a 401, rethrow it to be handled by the application
      throw error;
    }
  }

  return response;
};

const dataProvider = simpleRestProvider(apiUrl, httpClient);

export default dataProvider;
