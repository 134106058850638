import React from "react";
import {
  SimpleForm,
  TextInput,
  Create,
  required,
  SelectInput,
} from "react-admin";
import { Grid, Box } from "@mui/material";

// Custom password validation function
const validatePassword = (value) => {
  const errors = [];
  if (!value) {
    errors.push("Password is required");
  }
  if (value.length < 8) {
    errors.push("Password must be at least 8 characters long");
  }
  if (!/[A-Z]/.test(value)) {
    errors.push("Password must contain at least one uppercase letter");
  }
  if (!/[a-z]/.test(value)) {
    errors.push("Password must contain at least one lowercase letter");
  }
  if (!/[0-9]/.test(value)) {
    errors.push("Password must contain at least one digit");
  }
  if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
    errors.push("Password must contain at least one special character");
  }
  return errors.length > 0 ? errors.join(", ") : undefined;
};

export const UserCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <Grid container width={"300px"}>
          <Box>
            <TextInput source="username" validate={required()} fullWidth />
            <SelectInput
              source="accessType"
              validate={required()}
              choices={["admin", "editor", "driver"]}
              fullWidth
            />
            <TextInput
              source="password"
              type="password"
              validate={[required(), validatePassword]}
              fullWidth
            />
          </Box>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
