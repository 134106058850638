import React from "react";
import { List, Datagrid, TextField, ShowButton } from "react-admin";

export const UserList = (props) => (
  <List {...props}>
    <Datagrid rowClick bulkActionButtons={false}>
      <ShowButton />
      <TextField source="username" />
      <TextField source="accessType" />
    </Datagrid>
  </List>
);
