const extractTime = (dateTime) => {
  const date = new Date(dateTime);
  return date.toTimeString().slice(0, 5); // Extracts the time in 'HH:MM' format
};

const convertToISOFormat = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString(); // Converts to 'YYYY-MM-DDTHH:mm:ss.sssZ'
};

export const generateSeferEkleXml = (data) => {
  const hareketSaati = extractTime(data.hareketTarihi);
  const seferBitisSaati = extractTime(data.seferBitisTarihi);
  const hareketTarihi = convertToISOFormat(data.hareketTarihi);
  const seferBitisTarihi = convertToISOFormat(data.seferBitisTarihi);
  return `
  <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:uet="http://uetds.unetws.udhb.gov.tr/">
     <soapenv:Header/>
     <soapenv:Body>
        <uet:seferEkle>
           <wsuser>
              <kullaniciAdi>${process.env.REACT_APP_SOAP_USERNAME}</kullaniciAdi>
              <sifre>${process.env.REACT_APP_SOAP_PASSWORD}</sifre>
           </wsuser>
           <ariziSeferBilgileriInput>
              <aracPlaka>${data.aracPlaka}</aracPlaka>
              <seferAciklama>${data.seferAciklama}</seferAciklama>
              <hareketTarihi>${hareketTarihi}</hareketTarihi>
              <hareketSaati>${hareketSaati}</hareketSaati>
              <seferBitisTarihi>${seferBitisTarihi}</seferBitisTarihi>
              <seferBitisSaati>${seferBitisSaati}</seferBitisSaati>
           </ariziSeferBilgileriInput>
        </uet:seferEkle>
     </soapenv:Body>
  </soapenv:Envelope>
  `;
};

export const generateSeferDetayCiktisiAlXml = (uetdsSeferReferansNo) => {
  return `
  <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:uet="http://uetds.unetws.udhb.gov.tr/">
     <soapenv:Header/>
     <soapenv:Body>
        <uet:seferDetayCiktisiAl>
           <wsuser>
              <kullaniciAdi>${process.env.REACT_APP_SOAP_USERNAME}</kullaniciAdi>
              <sifre>${process.env.REACT_APP_SOAP_PASSWORD}</sifre>
           </wsuser>
           <uetdsSeferReferansNo>${uetdsSeferReferansNo}</uetdsSeferReferansNo>
        </uet:seferDetayCiktisiAl>
     </soapenv:Body>
  </soapenv:Envelope>
  `;
};

export const generatePersonelEkleXml = (data) => {
  return `
   <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:uet="http://uetds.unetws.udhb.gov.tr/">
      <soapenv:Header/>
      <soapenv:Body>
         <uet:personelEkle>
            <wsuser>
               <kullaniciAdi>${
                 process.env.REACT_APP_SOAP_USERNAME
               }</kullaniciAdi>
               <sifre>${process.env.REACT_APP_SOAP_PASSWORD}</sifre>
            </wsuser>
            <uetdsSeferReferansNo>${
              data.uetdsSeferReferansNo
            }</uetdsSeferReferansNo>
            <seferPersonelBilgileriInput>
               <turKodu>${data.seferPersonelBilgileriInput.turKodu}</turKodu>
               <uyrukUlke>${
                 data.seferPersonelBilgileriInput.uyrukUlke
               }</uyrukUlke>
               <tcKimlikPasaportNo>${
                 data.seferPersonelBilgileriInput.tcKimlikPasaportNo
               }</tcKimlikPasaportNo>
               <cinsiyet>${data.seferPersonelBilgileriInput.cinsiyet}</cinsiyet>
               <adi>${data.seferPersonelBilgileriInput.adi}</adi>
               <soyadi>${data.seferPersonelBilgileriInput.soyadi}</soyadi>
               <telefon>${data.seferPersonelBilgileriInput.telefon}</telefon>
               <adres>${data.seferPersonelBilgileriInput.adres || ""}</adres>
               <hesKodu>${
                 data.seferPersonelBilgileriInput.hesKodu || ""
               }</hesKodu>
            </seferPersonelBilgileriInput>
         </uet:personelEkle>
      </soapenv:Body>
   </soapenv:Envelope>
   `;
};

export const generateSeferGrupEkleXml = (data) => {
  return `
    <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:uet="http://uetds.unetws.udhb.gov.tr/">
       <soapenv:Header/>
       <soapenv:Body>
          <uet:seferGrupEkle>
             <wsuser>
                <kullaniciAdi>${process.env.REACT_APP_SOAP_USERNAME}</kullaniciAdi>
                <sifre>${process.env.REACT_APP_SOAP_PASSWORD}</sifre>
             </wsuser>
             <uetdsSeferReferansNo>${data.uetdsSeferReferansNo}</uetdsSeferReferansNo>
             <seferGrupBilgileriInput>
                <grupAciklama>${data.grupAciklama}</grupAciklama>
                <baslangicUlke>${data.baslangicUlke}</baslangicUlke>
                <baslangicIl>${data.baslangicIl}</baslangicIl>
                <baslangicIlce>${data.baslangicIlce}</baslangicIlce>
                <baslangicYer>${data.baslangicYer}</baslangicYer>
                <bitisUlke>${data.bitisUlke}</bitisUlke>
                <bitisIl>${data.bitisIl}</bitisIl>
                <bitisIlce>${data.bitisIlce}</bitisIlce>
                <bitisYer>${data.bitisYer}</bitisYer>
                <grupAdi>${data.grupAdi}</grupAdi>
                <grupUcret>${data.grupUcret}</grupUcret>
             </seferGrupBilgileriInput>
          </uet:seferGrupEkle>
       </soapenv:Body>
    </soapenv:Envelope>
    `;
};

export const generateYolcuEkleCokluXml = (data) => {
  const { uetdsSeferReferansNo, yolcuBilgileriList } = data;

  const yolcuBilgileriXml = yolcuBilgileriList
    .map(
      (yolcu) => `
       <yolcuBilgileri>
         <uyrukUlke>${yolcu.uyrukUlke}</uyrukUlke>
         <tcKimlikPasaportNo>11111111111</tcKimlikPasaportNo>
         <cinsiyet></cinsiyet>
         <adi>${yolcu.adi}</adi>
         <soyadi>${yolcu.soyadi}</soyadi>
         <koltukNo></koltukNo>
         <telefonNo></telefonNo>
         <grupId>${yolcu.grupId}</grupId>
         <hesKodu></hesKodu>
       </yolcuBilgileri>`
    )
    .join(""); // Combine all the `yolcuBilgileri` entries into one string

  return `
    <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:uet="http://uetds.unetws.udhb.gov.tr/">
       <soapenv:Header/>
       <soapenv:Body>
          <uet:yolcuEkleCoklu>
             <wsuser>
                <kullaniciAdi>${process.env.REACT_APP_SOAP_USERNAME}</kullaniciAdi>
                <sifre>${process.env.REACT_APP_SOAP_PASSWORD}</sifre>
             </wsuser>
             <uetdsSeferReferansNo>${uetdsSeferReferansNo}</uetdsSeferReferansNo>
             ${yolcuBilgileriXml}
          </uet:yolcuEkleCoklu>
       </soapenv:Body>
    </soapenv:Envelope>
    `;
};
