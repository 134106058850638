import React from "react";
import { Typography, Box } from "@mui/material";
import {
  TextField,
  Show,
  SimpleShowLayout,
  DateField,
  WithRecord,
} from "react-admin";
import { WelcomingClipboard } from "../../utils/SummaryClipboard";
import { DownloadPdfButton } from "../../utils/DownloadPdfButton";
import { SendUetdsButton } from "../../uetds/sendSoapButton";
import { DownloadUetdsPdfButton } from "../../uetds/downloadPdfButton";

export const ItemShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <Box sx={{ display: "flex", gap: "5px" }}>
        <DownloadPdfButton />
        <SendUetdsButton />
        <DownloadUetdsPdfButton />
      </Box>
      <Typography variant="h6"> Order Details </Typography>
      <TextField source="orderNo" />
      <TextField source="channel" />
      <TextField source="status" />
      <DateField source="pickupDate" />
      <TextField source="pickupTime" />
      <TextField source="details" multiline="true" minrows={3} />
      <Typography variant="h6" sx={{ mt: 6 }}>
        Passenger Details
      </Typography>
      <TextField source="passengerName" />
      <TextField source="additionalPassengers" multiline="true" minrows={3} />
      <TextField source="passengerPhone" />
      <Typography variant="h6" sx={{ mt: 6 }}>
        Address Details
      </Typography>
      <TextField source="origin" label="From" />
      <TextField source="destination" label="To" />
      <TextField source="originAddressDetails" />
      <TextField source="destinationAddressDetails" />
      <Typography variant="h6" sx={{ mt: 6 }}>
        Flight Details
      </Typography>
      <TextField source="flightNumber" />
      <TextField source="welcomingTeam" />
      <Typography variant="h6" sx={{ mt: 6 }}>
        Payment Details
      </Typography>
      <TextField source="price" />
      <TextField source="priceCurrency" />
      <TextField source="paymentType" />
      <TextField source="paymentStatus" />
      <Typography variant="h6" sx={{ mt: 6 }}>
        Driver Details
      </Typography>
      <TextField source="driverAccount" />
      <TextField source="vehicleType" />
      <TextField source="vehiclePlate" />
      <TextField source="driverMobile" />
      <TextField source="driverCost" />
      <TextField source="driverCostStatus" />
    </SimpleShowLayout>
  </Show>
);
