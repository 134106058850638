const apiUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/api/admin"
    : process.env.REACT_APP_API_URL;

const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(`${apiUrl}/login`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
      credentials: "include",
    });

    return fetch(request)
      .then((response) => {
        if (!response.ok) throw new Error("Network response was not ok");
        return response.json();
      })
      .then((json) => {
        // Store the token and role in local storage
        localStorage.setItem(
          "auth",
          JSON.stringify({
            token: json.token,
            role: json.user.role,
          })
        );
        return json;
      })
      .catch((error) => {
        throw error;
      });
  },

  logout: () => {
    // Clear the refresh token cookie on the server
    return fetch(`${apiUrl}/logout`, {
      method: "POST",
      credentials: "include", // Send the cookie with the request
    }).then(() => {
      localStorage.removeItem("auth");
      return Promise.resolve();
    });
  },

  checkError: async (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      try {
        // Attempt to refresh the access token
        const refreshResponse = await fetch(`${apiUrl}/refresh_token`, {
          method: "POST",
          credentials: "include", // Include the refresh token cookie
        });

        if (refreshResponse.ok) {
          const json = await refreshResponse.json();
          const newAccessToken = json.accessToken;

          // Update the access token in local storage
          const auth = JSON.parse(localStorage.getItem("auth"));
          auth.token = newAccessToken;
          localStorage.setItem("auth", JSON.stringify(auth));

          // Retry the original request (you may need to pass the original request here)
          return Promise.resolve();
        } else {
          // If the refresh fails, remove the auth and log the user out
          localStorage.removeItem("auth");
          return Promise.reject();
        }
      } catch (err) {
        localStorage.removeItem("auth");
        return Promise.reject();
      }
    }
    return Promise.resolve();
  },

  checkAuth: () => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      const parsedAuth = JSON.parse(auth);
      if (parsedAuth.token) {
        return Promise.resolve();
      }
    }
    return Promise.reject();
  },

  getPermissions: () => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    return auth && auth.role ? Promise.resolve(auth.role) : Promise.reject();
  },
};

export default authProvider;
