import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Autocomplete,
  Box,
} from "@mui/material";
import { useRecordContext } from "react-admin";
import soapClient from "./soapClient";
import {
  generateSeferEkleXml,
  generatePersonelEkleXml,
  generateSeferGrupEkleXml,
  generateYolcuEkleCokluXml,
} from "./soapRequests";
import axios from "axios";
import { DOMParser } from "xmldom"; // Ensure you have a suitable XML parser
import { driverProfiles } from "./driverProfiles";
import SeferGrupEkle from "./seferGrupEkle";

const apiUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/api/admin"
    : process.env.REACT_APP_API_URL;

const combineDateAndTime = (dateString, timeString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}T${timeString}`;
};

const addHoursWithoutTimezoneOffset = (dateString, timeString, hoursToAdd) => {
  const combinedDatetime = combineDateAndTime(dateString, timeString);
  const date = new Date(combinedDatetime);
  date.setHours(date.getHours() + hoursToAdd);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const SendUetdsButton = () => {
  const record = useRecordContext(); // Get the current item record
  const [open, setOpen] = useState(false);
  const [referansNo, setReferansNo] = useState(null); // State to store referansNo
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const defaultDriver =
    driverProfiles.find(
      (driver) =>
        driver.driverPlate ===
        (record.vehiclePlate ? record.vehiclePlate.replace(/\s+/g, "") : "")
    ) || driverProfiles[0]; // Fallback to the first driver if no match

  const [selectedDriver, setSelectedDriver] = useState(defaultDriver);

  const [formData, setFormData] = useState({
    aracPlaka: record.vehiclePlate || "",
    seferAciklama: "WHITELOTUS TURIZM SEYAHAT ACENTASI",
    hareketTarihi: combineDateAndTime(record.pickupDate, record.pickupTime),
    seferBitisTarihi: addHoursWithoutTimezoneOffset(
      record.pickupDate,
      record.pickupTime,
      3
    ),
    price: "200",
  });

  const [seferGrupData, setSeferGrupData] = useState({
    baslangicUlke: "TR",
    baslangicIl: "",
    baslangicIlce: "",
    baslangicYer: record.origin,
    bitisUlke: "TR",
    bitisIl: "",
    bitisIlce: "",
    bitisYer: record.destination,
  });

  useEffect(() => {
    const fetchCountries = async () => {
      const response = await fetch("/countries.json");
      const data = await response.json();
      setCountries(data);
      const defaultCountry = data.find((country) => country.KOD === "RU");
      setSelectedCountry(defaultCountry);
    };

    fetchCountries();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDriverChange = (e) => {
    const driver = driverProfiles.find(
      (profile) => profile.tcKimlikPasaportNo === e.target.value
    );
    setSelectedDriver(driver);
  };

  const handleSubmit = async () => {
    try {
      // Step 1: Send the seferEkle request and get uetdsSeferReferansNo
      const seferXml = generateSeferEkleXml(formData);
      const seferResponse = await soapClient(
        process.env.REACT_APP_SOAP_ENDPOINT,
        seferXml
      );

      let xmlDoc;
      if (typeof seferResponse === "string") {
        xmlDoc = new DOMParser().parseFromString(seferResponse, "text/xml");
      } else {
        xmlDoc = seferResponse;
      }
      const referansNoElement = xmlDoc.getElementsByTagName(
        "uetdsSeferReferansNo"
      )[0];

      const retrievedReferansNo = referansNoElement
        ? referansNoElement.textContent
        : null;

      console.log("Personel Ekle Response:", seferResponse);

      setReferansNo(retrievedReferansNo);

      if (retrievedReferansNo) {
        const token = JSON.parse(localStorage.getItem("auth"))?.token;
        await axios.put(
          `${apiUrl}/orders/${record.id}`,
          { uetdsSeferReferansNo: retrievedReferansNo },
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the authorization token in the request
            },
          }
        );

        // Step 2: Send the personelEkle request
        const personelXml = generatePersonelEkleXml({
          uetdsSeferReferansNo: retrievedReferansNo,
          seferPersonelBilgileriInput: selectedDriver,
        });

        const personelResponse = await soapClient(
          process.env.REACT_APP_SOAP_ENDPOINT,
          personelXml
        );

        console.log("Personel Ekle Response:", personelResponse);

        await soapClient(process.env.REACT_APP_SOAP_ENDPOINT, personelXml);

        // Step 3: Send the seferGrupEkle request
        const seferGrupXml = generateSeferGrupEkleXml({
          uetdsSeferReferansNo: retrievedReferansNo,
          ...seferGrupData,
        });

        const seferGrupResponse = await soapClient(
          process.env.REACT_APP_SOAP_ENDPOINT,
          seferGrupXml
        );
        console.log("Sefer Grup Response:", seferGrupResponse);
        const grupRefNoElement = new DOMParser()
          .parseFromString(seferGrupResponse, "text/xml")
          .getElementsByTagName("uetdsGrupRefNo")[0];

        const grupRefNo = grupRefNoElement
          ? grupRefNoElement.textContent
          : null;

        // Step 4: Dynamically create the yolcuBilgileriList
        if (grupRefNo) {
          const yolcuBilgileriList = [
            {
              uyrukUlke: selectedCountry ? selectedCountry.KOD : "",
              adi: record.passengerName.split(" ")[0],
              soyadi: record.passengerName.split(" ")[1] || "",
              grupId: grupRefNo,
            },
          ];

          if (record.additionalPassengers) {
            const additionalPassengers =
              record.additionalPassengers.split(/\r?\n/);
            additionalPassengers.forEach((passenger) => {
              yolcuBilgileriList.push({
                uyrukUlke: selectedCountry ? selectedCountry.KOD : "",
                adi: passenger.split(" ")[0],
                soyadi: passenger.split(" ")[1] || "",
                grupId: grupRefNo,
              });
            });
          }

          // Generate and send the yolcuEkleCoklu request
          const yolcuEkleCokluXml = generateYolcuEkleCokluXml({
            uetdsSeferReferansNo: retrievedReferansNo,
            yolcuBilgileriList,
          });

          const yolcuEkleCokluResponse = await soapClient(
            process.env.REACT_APP_SOAP_ENDPOINT,
            yolcuEkleCokluXml
          );

          console.log("Yolcu Ekle Coklu Response:", yolcuEkleCokluXml);
          console.log("Yolcu Ekle Coklu Response:", yolcuEkleCokluResponse);
        } else {
          console.error("Grup Ref No not generated.");
        }
      } else {
        console.error("Referans No not generated.");
      }
    } catch (error) {
      console.error("Error sending SOAP request:", error);
    } finally {
      setOpen(false);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
        sx={{ width: "175px", fontSize: "12px" }}
      >
        Send UETDS
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Send UETDS</DialogTitle>
        <DialogContent>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            gap={"5px"}
            padding={"10px 0"}
          >
            <TextField
              label="Vehicle Plate"
              name="aracPlaka"
              value={formData.aracPlaka}
              onChange={handleInputChange}
              fullWidth
            />
            <TextField
              label="Driver"
              select
              value={selectedDriver.tcKimlikPasaportNo}
              onChange={handleDriverChange}
              fullWidth
            >
              {driverProfiles.map((driver) => (
                <MenuItem
                  key={driver.tcKimlikPasaportNo}
                  value={driver.tcKimlikPasaportNo}
                >
                  {`${driver.adi} ${driver.soyadi}`}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            gap={"5px"}
            padding={"10px 0"}
          >
            <TextField
              label="Departure Date"
              name="hareketTarihi"
              type="datetime-local"
              value={formData.hareketTarihi}
              onChange={handleInputChange}
              fullWidth
            />

            <TextField
              label="End Date"
              name="seferBitisTarihi"
              type="datetime-local"
              value={formData.seferBitisTarihi}
              onChange={handleInputChange}
              fullWidth
            />
          </Box>

          <Box
            display={"flex"}
            justifyContent={"space-between"}
            gap={"5px"}
            padding={"10px 0"}
          >
            <TextField
              label="Price TRY"
              name="price"
              value={formData.price}
              onChange={handleInputChange}
              fullWidth
            />

            <Autocomplete
              fullWidth
              options={countries}
              getOptionLabel={(option) => option.ULKE_ADI}
              renderOption={(props, option) => (
                <li {...props} key={option.KOD}>
                  {option.ULKE_ADI}
                </li>
              )}
              value={selectedCountry}
              onChange={(event, newValue) => setSelectedCountry(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Passenger Country" />
              )}
            />
          </Box>

          <SeferGrupEkle
            record={record}
            price={formData.price}
            onProvinceChange={(newProvince, type) =>
              setSeferGrupData((prevData) => ({
                ...prevData,
                [type === "baslangic" ? "baslangicIl" : "bitisIl"]:
                  newProvince?.il_kodu || "",
              }))
            }
            onDistrictChange={(newDistrict, type) =>
              setSeferGrupData((prevData) => ({
                ...prevData,
                [type === "baslangic" ? "baslangicIlce" : "bitisIlce"]:
                  newDistrict?.ilce_kodu || "",
              }))
            }
            setSeferGrupData={setSeferGrupData}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
