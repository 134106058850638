import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
} from "react-admin";

export const UserEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm width={"300px"}>
        <TextInput source="_id" disabled fullWidth />
        <TextInput source="username" disabled fullWidth />
        <SelectInput
          source="accessType"
          validate={required()}
          choices={["admin", "editor", "driver"]}
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};
