import React from "react";
import { AppBar, Title, useSidebarState, Layout } from "react-admin";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { createTheme } from "@mui/material/styles";

export const CustomAppBar = (props) => {
  const [open, setOpen] = useSidebarState();

  return (
    <AppBar {...props}>
      <Box display="flex" alignItems="center" flex="1">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setOpen(!open)}
          edge="start"
        ></IconButton>
        <img
          src="logo.png"
          alt="Logo"
          style={{ height: "35px", marginLeft: "2px" }}
        />
        <Title />
      </Box>
    </AppBar>
  );
};

export const customTheme = createTheme({
  palette: {
    primary: {
      main: "#0a0908",
    },
    warning: {
      main: "#f8f9fa",
    },
  },

  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#0a0908", // Your desired top bar color
        },
      },
    },
  },
});

export const CustomLayout = (props) => (
  <Layout {...props} appBar={CustomAppBar} />
);
