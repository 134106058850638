import React from "react";
import { TextField, Show, SimpleShowLayout } from "react-admin";

export const UserShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="_id" />
      <TextField source="username" />
      <TextField source="accessType" />
    </SimpleShowLayout>
  </Show>
);
