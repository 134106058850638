import React from "react";
import { ItemFormFields } from "./itemsCreate";
import {
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  CreateButton,
  DeleteButton,
  usePermissions,
} from "react-admin";

const CustomToolbar = ({ permissions }) => (
  <Toolbar>
    <SaveButton />
    {permissions === "admin" && (
      <DeleteButton sx={{ marginLeft: "auto" }} />
    )}{" "}
  </Toolbar>
);

export const ItemEdit = (props) => {
  const { permissions } = usePermissions();

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar permissions={permissions} />}>
        <ItemFormFields />
      </SimpleForm>
    </Edit>
  );
};
