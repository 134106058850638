import React from "react";
import { Button, Box } from "@mui/material";
import { useRecordContext } from "react-admin";
import { useDataProvider } from "react-admin";

export const SummaryClipboard = () => {
  const record = useRecordContext();
  if (!record) return null;

  const paymentNote =
    record.paymentType === "cash"
      ? `${record.price} ${record.priceCurrency.toUpperCase()} NAKIT`
      : "ODEME HAVALE/EFT";

  const dateStr = record.pickupDate;
  const date = new Date(dateStr);
  const formattedDate = date.toLocaleDateString("en-GB"); // 'en-GB' locale for 'DD/MM/YYYY' format

  const content = `*ID:* ${record.orderNo}
*Alış Tarihi:* ${formattedDate}
*Alış Saati:* ${record.pickupTime}
*Alış Adresi:* ${record.origin}
*Varış Adresi:* ${record.destination}
*Uçuş No:* ${record.flightNumber}
*Bütçe:* ${record.driverCost}
-------------------
*Yolcu Listesi:*
${record.passengerName}
${record.additionalPassengers}
-------------------
*Alış Açık Adres:*
${record.originAddressDetails}
-------------------
*Varış Açık Adres:*
${record.destinationAddressDetails}
-------------------
*NOT:* ${paymentNote}
${record.details}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(content);
  };

  return (
    <Box mt={0}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleCopy}
        sx={{ fontSize: "12px" }}
      >
        COPY
      </Button>
    </Box>
  );
};

export const WelcomingClipboard = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();

  const dateStr = record.pickupDate;
  const date = new Date(dateStr);
  const formattedDate = date.toLocaleDateString("en-GB"); // 'en-GB' locale for 'DD/MM/YYYY' format

  const content = `*ID:* ${record.orderNo}
*${record.welcomingTeam} Karşılama Bilgisi:*
${formattedDate} Saat ${record.pickupTime}
${record.flightNumber}

${record.passengerName}
${record.additionalPassengers}

*İş Sahibi:*
Nurullah +90 530 925 01 64`;

  const handleCopy = async () => {
    navigator.clipboard.writeText(content);
    if (record?.welcomingTeam) {
      // Update the record to set isWlcSent to "yes"
      await dataProvider.update("orders", {
        id: record.id,
        data: { isWlcSent: "yes" },
        previousData: record,
      });
    }
  };

  return (
    <Box mt={0}>
      <Button
        color={!record?.isWlcSent ? "warning" : "primary"}
        variant="contained"
        onClick={handleCopy}
        disabled={!record?.welcomingTeam}
        sx={{ fontSize: "12px" }}
      >
        WLC
      </Button>
    </Box>
  );
};
