import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, Box } from "@mui/material";
import { useLocationData } from "./ilceList"; // Adjust the path as needed

const SeferGrupEkle = ({
  record,
  onProvinceChange,
  onDistrictChange,
  setSeferGrupData,
  price,
}) => {
  const { locationData, uniqueIl } = useLocationData();

  const [selectedBaslangicProvince, setSelectedBaslangicProvince] =
    useState(null);
  const [selectedBaslangicDistrict, setSelectedBaslangicDistrict] =
    useState(null);
  const [baslangicDistrictOptions, setBaslangicDistrictOptions] = useState([]);

  const [selectedBitisProvince, setSelectedBitisProvince] = useState(null);
  const [selectedBitisDistrict, setSelectedBitisDistrict] = useState(null);
  const [bitisDistrictOptions, setBitisDistrictOptions] = useState([]);

  // Set default values once data is loaded
  useEffect(() => {
    const istanbulProvince =
      uniqueIl.find((province) => province.il_kodu === "34") || null;

    if (istanbulProvince) {
      setSelectedBaslangicProvince(istanbulProvince);
      setSelectedBitisProvince(istanbulProvince);
    }
  }, [uniqueIl]);

  useEffect(() => {
    if (selectedBaslangicProvince) {
      const districtOptions = locationData.filter(
        (district) => district.il_kodu === selectedBaslangicProvince.il_kodu
      );
      setBaslangicDistrictOptions(districtOptions);
      const defaultDistrict = districtOptions.find(
        (district) => district.ilce_kodu === "2048"
      );

      setSelectedBaslangicDistrict(
        defaultDistrict || districtOptions[0] || null
      ); // Automatically select the first district
    }
  }, [selectedBaslangicProvince, locationData]);

  useEffect(() => {
    if (selectedBitisProvince) {
      const districtOptions = locationData.filter(
        (district) => district.il_kodu === selectedBitisProvince.il_kodu
      );
      setBitisDistrictOptions(districtOptions);
      const defaultDistrict = districtOptions.find(
        (district) => district.ilce_kodu === "2048"
      );
      setSelectedBitisDistrict(defaultDistrict || districtOptions[0] || null); // Automatically select the first district
    }
  }, [selectedBitisProvince, locationData]);

  useEffect(() => {
    setSeferGrupData({
      baslangicUlke: "TR",
      baslangicIl: selectedBaslangicProvince?.il_kodu || "",
      baslangicIlce: selectedBaslangicDistrict?.ilce_kodu || "",
      baslangicYer: record.origin,
      bitisUlke: "TR",
      bitisIl: selectedBitisProvince?.il_kodu || "",
      bitisIlce: selectedBitisDistrict?.ilce_kodu || "",
      bitisYer: record.destination,
      grupAciklama: "WHITELOTUS TURIZM ACENTASI",
      grupAdi: "TRANSFER",
      grupUcret: price || "200", // Default price set to 200 if not provided
    });
  }, [
    selectedBaslangicProvince,
    selectedBaslangicDistrict,
    selectedBitisProvince,
    selectedBitisDistrict,
    record.origin,
    record.destination,
    price,
    setSeferGrupData,
  ]);

  const handleProvinceChange = (event, newValue, type) => {
    if (type === "baslangic") {
      setSelectedBaslangicProvince(newValue);
      setSelectedBaslangicDistrict(null);
    } else {
      setSelectedBitisProvince(newValue);
      setSelectedBitisDistrict(null);
    }
    onProvinceChange(newValue);
  };

  const handleDistrictChange = (event, newValue, type) => {
    if (type === "baslangic") {
      setSelectedBaslangicDistrict(newValue);
    } else {
      setSelectedBitisDistrict(newValue);
    }
    onDistrictChange(newValue);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        gap="5px"
        padding="10px 0"
      >
        <Autocomplete
          fullWidth
          options={uniqueIl}
          getOptionLabel={(option) => `${option.il_adi}`}
          renderOption={(props, option) => (
            <li {...props} key={`${option.il_kodu}-${option.il_adi}`}>
              {option.il_adi}
            </li>
          )}
          value={selectedBaslangicProvince}
          onChange={(event, newValue) =>
            handleProvinceChange(event, newValue, "baslangic")
          }
          renderInput={(params) => (
            <TextField {...params} label="Select Starting Province" fullWidth />
          )}
        />

        <Autocomplete
          fullWidth
          options={baslangicDistrictOptions}
          getOptionLabel={(option) => option.ilce_adi}
          renderOption={(props, option) => (
            <li {...props} key={`${option.il_kodu}-${option.ilce_kodu}`}>
              {option.ilce_adi}
            </li>
          )}
          value={selectedBaslangicDistrict}
          onChange={(event, newValue) =>
            handleDistrictChange(event, newValue, "baslangic")
          }
          disabled={!selectedBaslangicProvince}
          renderInput={(params) => (
            <TextField {...params} label="Select Starting District" />
          )}
        />
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        gap="5px"
        padding="10px 0"
      >
        <Autocomplete
          fullWidth
          options={uniqueIl}
          getOptionLabel={(option) => `${option.il_adi}`}
          renderOption={(props, option) => (
            <li {...props} key={`${option.il_kodu}-${option.il_adi}`}>
              {option.il_adi}
            </li>
          )}
          value={selectedBitisProvince}
          onChange={(event, newValue) =>
            handleProvinceChange(event, newValue, "bitis")
          }
          renderInput={(params) => (
            <TextField {...params} label="Select Ending Province" />
          )}
        />

        <Autocomplete
          fullWidth
          options={bitisDistrictOptions}
          getOptionLabel={(option) => option.ilce_adi}
          renderOption={(props, option) => (
            <li {...props} key={`${option.il_kodu}-${option.ilce_kodu}`}>
              {option.ilce_adi}
            </li>
          )}
          value={selectedBitisDistrict}
          onChange={(event, newValue) =>
            handleDistrictChange(event, newValue, "bitis")
          }
          disabled={!selectedBitisProvince}
          renderInput={(params) => (
            <TextField {...params} label="Select Ending District" />
          )}
        />
      </Box>
    </>
  );
};

export default SeferGrupEkle;
