import React from "react";
import { Button } from "@mui/material";
import { useRecordContext } from "react-admin";
import soapClient from "./soapClient"; // Import your SOAP client
import { generateSeferDetayCiktisiAlXml } from "./soapRequests"; // Import the XML generator for the SOAP request

export const DownloadUetdsPdfButton = () => {
  const record = useRecordContext(); // Get the current item record

  const handleDownloadPdf = async () => {
    if (!record.uetdsSeferReferansNo) {
      return;
    }

    try {
      const xml = generateSeferDetayCiktisiAlXml(record.uetdsSeferReferansNo);
      const response = await soapClient(
        process.env.REACT_APP_SOAP_ENDPOINT,
        xml
      );

      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response, "text/xml");

      // Try to find the PDF data element in the response
      const pdfBase64 = xmlDoc.getElementsByTagName("sonucPdf")[0]?.textContent;

      if (pdfBase64) {
        const pdfData = atob(pdfBase64);
        const blob = new Blob(
          [Uint8Array.from(pdfData, (c) => c.charCodeAt(0))],
          {
            type: "application/pdf",
          }
        );
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `UETDS_${record.uetdsSeferReferansNo}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        console.error("PDF data not found in the response.");
      }
    } catch (error) {
      console.error("Error downloading UETDS PDF:", error);
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleDownloadPdf}
      disabled={!record?.uetdsSeferReferansNo}
      sx={{ width: "175px", fontSize: "12px" }}
    >
      Download UETDS
    </Button>
  );
};
