import React from "react";
import { SummaryClipboard } from "../../utils/SummaryClipboard";
import { usePermissions } from "react-admin";
import { WelcomingClipboard } from "../../utils/SummaryClipboard";
import { Box } from "@mui/material";

import {
  List,
  DatagridConfigurable,
  TextField,
  TextInput,
  ShowButton,
  SelectInput,
  DateInput,
  WithRecord,
  DateField,
  SelectColumnsButton,
  TopToolbar,
  FilterButton,
  CreateButton,
  ExportButton,
  SearchInput,
} from "react-admin";

import {
  welcomingTeamChoices,
  statusChoices,
  channelsChoices,
  paymentStatusChoices,
  paymentTypeChoices,
  vehicleTypeChoices,
} from "../../utils/choices";

const today = new Date().toISOString().split("T")[0];

const combineDateAndTime = (dateString, timeString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}T${timeString}`;
};
const hareketTarihi = (record) =>
  combineDateAndTime(record.pickupDate, record.pickupTime);
const getMinutesDifference = (record) => {
  const now = new Date();
  const hareketTarihiDate = new Date(hareketTarihi(record));
  const diffInMs = hareketTarihiDate - now;
  const diffInMinutes = Math.floor(diffInMs / 1000 / 60);
  return diffInMinutes;
};

export const getRowSx = (record) => {
  const minutesDifference = getMinutesDifference(record);

  return {
    bgcolor:
      minutesDifference < 60 &&
      minutesDifference >= -30 &&
      record.origin !== "İHL" &&
      record.origin !== "SAW" &&
      record.status === "pending"
        ? "#ffee99"
        : record.status === "pending"
        ? "#fff6cc"
        : record.status === "completed"
        ? "#d8f3dc"
        : record.status === "inProgress"
        ? "#f0ead2"
        : "#fae0e4",
    "& .MuiTypography-root": {
      color: "#000",
    },
    "&:hover": {
      bgcolor: "#F5F5F5 !important",
    },
  };
};
export const itemFilters = [
  <SearchInput source="q" alwaysOn sx={{ maxWidth: "300px" }} />,
  <TextInput source="orderNo" sx={{ maxWidth: "300px" }} />,
  <TextInput source="driverAccount" sx={{ maxWidth: "300px" }} />,
  <SelectInput source="status" choices={statusChoices} />,
  <DateInput source="pickupDate" alwaysOn sx={{ maxWidth: "300px" }} />,
  <DateInput
    source="pickupDate_gte"
    label="Pickup Date From"
    sx={{ maxWidth: "300px" }}
  />,
  <DateInput
    source="pickupDate_lte"
    label="Pickup Date To"
    sx={{ maxWidth: "300px" }}
  />,
  <TextInput source="origin" label="From" sx={{ maxWidth: "300px" }} />,
  <TextInput source="destination" label="To" sx={{ maxWidth: "300px" }} />,
  <TextInput source="flightNumber" sx={{ maxWidth: "300px" }} />,
  <TextInput source="passengerPhone" sx={{ maxWidth: "300px" }} />,
  <SelectInput
    source="driverCostStatus"
    choices={paymentStatusChoices}
    sx={{ maxWidth: "300px" }}
  />,
  <TextInput source="passengerName" />,
  <SelectInput
    source="welcomingTeam"
    choices={welcomingTeamChoices}
    sx={{ maxWidth: "300px" }}
  />,
  <TextInput source="driverMobile" sx={{ maxWidth: "300px" }} />,
  <TextInput source="vehiclePlate" sx={{ maxWidth: "300px" }} />,
  <SelectInput
    source="vehicleType"
    choices={vehicleTypeChoices}
    sx={{ maxWidth: "300px" }}
  />,
  <SelectInput
    source="paymentType"
    choices={paymentTypeChoices}
    sx={{ maxWidth: "300px" }}
  />,
  <SelectInput
    source="paymentStatus"
    choices={paymentStatusChoices}
    sx={{ maxWidth: "300px" }}
  />,
  <SelectInput
    source="channel"
    choices={channelsChoices}
    sx={{ maxWidth: "300px" }}
  />,
];

const ListActions = () => {
  const { permissions } = usePermissions(); // Assuming permissions come from here

  return (
    <TopToolbar>
      <SelectColumnsButton />
      <FilterButton />
      {(permissions === "admin" || permissions === "editor") && (
        <CreateButton />
      )}
      <ExportButton />
    </TopToolbar>
  );
};

const ListButtons = () => {
  const { permissions } = usePermissions(); // Get permissions

  if (permissions === "admin" || permissions === "editor") {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <WithRecord render={() => <SummaryClipboard />} />
        <WithRecord render={() => <WelcomingClipboard />} />
      </Box>
    );
  }

  return null;
};

export const ItemList = () => (
  <List
    actions={<ListActions />}
    filters={itemFilters}
    filterDefaultValues={{ pickupDate: today }}
    sort={{ field: "pickupTime", order: "ASC" }}
    perPage={50}
  >
    <DatagridConfigurable
      bulkActionButtons={false}
      rowClick={false}
      rowSx={getRowSx}
      omit={[
        "status",
        "channel",
        "additionalPassengers",
        "originAddressDetails",
        "destinationAddressDetails",
        "welcomingTeam",
        "price",
        "priceCurrency",
        "paymentStatus",
        "driverAccount",
        "vehiclePlate",
        "driverCost",
        "driverCostStatus",
      ]}
    >
      <ShowButton />
      <TextField source="channel" />
      <TextField source="status" />
      <TextField source="orderNo" />
      <DateField source="pickupDate" />
      <TextField source="pickupTime" />
      <TextField source="origin" label="From" />
      <TextField source="destination" label="To" />
      <TextField source="driverMobile" />
      <TextField source="passengerName" />
      <TextField source="passengerPhone" />
      <TextField source="vehicleType" />
      <TextField source="paymentType" />
      <TextField source="flightNumber" />
      <TextField source="details" />
      <TextField source="additionalPassengers" />
      <TextField source="originAddressDetails" />
      <TextField source="destinationAddressDetails" />
      <TextField source="welcomingTeam" />
      <TextField source="price" />
      <TextField source="priceCurrency" />
      <TextField source="paymentStatus" />
      <TextField source="driverAccount" />
      <TextField source="vehiclePlate" />
      <TextField source="driverCost" />
      <TextField source="driverCostStatus" />
      <ListButtons />
    </DatagridConfigurable>
  </List>
);
