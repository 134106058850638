import React from "react";
import { Login, LoginForm } from "react-admin";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
} from "@mui/material";

const CustomLoginForm = (props) => (
  <Box>
    <Card>
      <CardContent>
        <Typography variant="h4" align="center" gutterBottom>
          IST11 Rides
        </Typography>
        <LoginForm {...props}>
          <TextField name="username" fullWidth required margin="normal" />
          <TextField
            name="password"
            type="password"
            fullWidth
            required
            margin="normal"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: "16px" }}
          >
            Sign In
          </Button>
        </LoginForm>
      </CardContent>
    </Card>
  </Box>
);

export const CustomLoginPage = (props) => (
  <Login
    {...props}
    sx={{
      backgroundColor: "#0a0908",
      backgroundImage: "none",
    }}
  >
    <CustomLoginForm />
  </Login>
);
