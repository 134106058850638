export const driverProfiles = [
  {
    turKodu: "0",
    uyrukUlke: "TR",
    tcKimlikPasaportNo: "13087776324",
    cinsiyet: "E",
    adi: "NIHAT",
    soyadi: "AKGUNDOGDU",
    telefon: "5346135723",
    driverPlate: "34CSR430",
  },
  {
    turKodu: "0",
    uyrukUlke: "TR",
    tcKimlikPasaportNo: "17002669326",
    cinsiyet: "E",
    adi: "ABDUSSAMET",
    soyadi: "EYVAZ",
    telefon: "5532200271",
    driverPlate: "34FVM584",
  },
];
