export const welcomingTeamChoices = [
  { id: "Q7", name: "Q7" },
  { id: "HR24", name: "HR24" },
];

export const statusChoices = [
  { id: "completed", name: "COMPLETED" },
  { id: "pending", name: "PENDING" },
  { id: "inProgress", name: "IN PROGRESS" },
  { id: "noShow", name: "NO SHOW" },
];

export const channelsChoices = [
  { id: "kiwi", name: "KIWI" },
  { id: "whatsapp", name: "WP" },
  { id: "ist11", name: "IST11" },
  { id: "get", name: "GET" },
];

export const currencyChoices = [
  { id: "try", name: "TRY" },
  { id: "eur", name: "EUR" },
  { id: "usd", name: "USD" },
];

export const paymentStatusChoices = [
  { id: "completed", name: "COMPLETED" },
  { id: "pending", name: "PENDING" },
];

export const paymentTypeChoices = [
  { id: "cash", name: "CASH" },
  { id: "transfer", name: "BANK TRANSFER" },
  { id: "creditCard", name: "CREDIT CARD" },
];

export const vehicleTypeChoices = [
  { id: "standard", name: "STANDARD" },
  { id: "luxury", name: "LUXURY" },
  { id: "sprinter", name: "SPRINTER" },
];
