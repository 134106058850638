import { useState, useEffect } from "react";
import { useDataProvider } from "react-admin";

// Hook to fetch driver accounts and return a list of choices
export const useDriverAccounts = () => {
  const [driverAccounts, setDriverAccounts] = useState([]);
  const dataProvider = useDataProvider();

  useEffect(() => {
    const fetchDriverAccounts = async () => {
      try {
        const { data } = await dataProvider.getList("users", {
          filter: { accessType: "driver" },
          pagination: { page: 1, perPage: 100 },
          sort: { field: "username", order: "ASC" },
        });

        const choices = data.map((user) => ({
          id: user.username,
          name: user.username,
        }));

        setDriverAccounts(choices);
      } catch (error) {
        console.error("Error fetching driver accounts:", error);
        setDriverAccounts([]); // Return empty array on error
      }
    };

    fetchDriverAccounts();
  }, [dataProvider]);

  return driverAccounts;
};
