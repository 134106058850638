import React from "react";
import { Grid, Box, Typography } from "@mui/material";

import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  SelectInput,
  required,
  AutocompleteInput,
} from "react-admin";

import {
  welcomingTeamChoices,
  statusChoices,
  channelsChoices,
  currencyChoices,
  paymentStatusChoices,
  paymentTypeChoices,
  vehicleTypeChoices,
} from "../../utils/choices";

import { useDriverAccounts } from "../../utils/driverAccounts";

const validateTime = (value) => {
  if (!value) {
    return "Required";
  }
  const isValid = /^(?:[01]\d|2[0-3]):[0-5]\d$/.test(value);
  return isValid ? undefined : "Must be a valid time in HH:mm format";
};

const GridItem = ({ children }) => (
  <Grid item width={"300px"} xs={12} sm={12} md={12} lg={12} xl={12}>
    {children}
  </Grid>
);

export const ItemFormFields = () => (
  <Grid container spacing={6}>
    {/* Order Details Section */}
    <GridItem>
      <Typography variant="h6" gutterBottom>
        Order Details
      </Typography>
      <Box>
        <GridItem>
          <SelectInput source="channel" choices={channelsChoices} fullWidth />
        </GridItem>
        <GridItem>
          <TextInput source="orderNo" fullWidth />
        </GridItem>
        <GridItem>
          <SelectInput
            source="status"
            choices={statusChoices}
            validate={required()}
            fullWidth
          />
        </GridItem>
        <GridItem>
          <DateInput source="pickupDate" validate={required()} fullWidth />
        </GridItem>
        <GridItem>
          <TextInput
            source="pickupTime"
            validate={[required(), validateTime]}
            fullWidth
          />
        </GridItem>
        <GridItem>
          <TextInput source="details" multiline minRows={3} fullWidth />
        </GridItem>
      </Box>
    </GridItem>

    {/* Passenger Details Section */}
    <GridItem>
      <Typography variant="h6" gutterBottom>
        Passenger Details
      </Typography>
      <Box>
        <GridItem>
          <TextInput source="passengerName" fullWidth />
        </GridItem>
        <GridItem>
          <TextInput
            source="additionalPassengers"
            multiline
            minRows={3}
            fullWidth
          />
        </GridItem>
        <GridItem>
          <TextInput source="passengerPhone" validate={required()} fullWidth />
        </GridItem>
      </Box>
    </GridItem>

    {/* Address Details Section */}
    <GridItem>
      <Typography variant="h6" gutterBottom>
        Address Details
      </Typography>
      <Box>
        <GridItem>
          <TextInput
            source="origin"
            label="From"
            validate={required()}
            fullWidth
          />
        </GridItem>
        <GridItem>
          <TextInput
            source="destination"
            label="To"
            validate={required()}
            fullWidth
          />
        </GridItem>
        <GridItem>
          <TextInput source="originAddressDetails" fullWidth />
        </GridItem>
        <GridItem>
          <TextInput source="destinationAddressDetails" fullWidth />
        </GridItem>
      </Box>
    </GridItem>

    {/* Flight Details Section */}
    <GridItem>
      <Typography variant="h6" gutterBottom>
        Flight Details
      </Typography>
      <Box>
        <GridItem>
          <TextInput source="flightNumber" fullWidth />
        </GridItem>
        <GridItem>
          <SelectInput
            source="welcomingTeam"
            choices={welcomingTeamChoices}
            fullWidth
          />
        </GridItem>
      </Box>
    </GridItem>

    {/* Payment Details Section */}
    <GridItem>
      <Typography variant="h6" gutterBottom>
        Payment Details
      </Typography>
      <Box>
        <GridItem>
          <TextInput source="price" validate={required()} fullWidth />
        </GridItem>
        <GridItem>
          <SelectInput
            source="priceCurrency"
            choices={currencyChoices}
            validate={required()}
            fullWidth
          />
        </GridItem>
        <GridItem>
          <SelectInput
            source="paymentType"
            choices={paymentTypeChoices}
            validate={required()}
            fullWidth
          />
        </GridItem>
        <GridItem>
          <SelectInput
            source="paymentStatus"
            choices={paymentStatusChoices}
            fullWidth
          />
        </GridItem>
      </Box>
    </GridItem>

    {/* Driver Details Section */}
    <GridItem item xs={12}>
      <Typography variant="h6" gutterBottom>
        Driver Details
      </Typography>
      <Box mb={2}>
        <GridItem>
          <SelectInput
            source="vehicleType"
            choices={vehicleTypeChoices}
            fullWidth
          />
        </GridItem>
        <GridItem>
          <TextInput source="vehiclePlate" fullWidth />
        </GridItem>
        <GridItem>
          <TextInput source="driverMobile" fullWidth />
        </GridItem>
        <GridItem>
          <TextInput source="driverCost" fullWidth />
        </GridItem>
        <GridItem>
          <SelectInput
            source="driverCostStatus"
            choices={paymentStatusChoices}
            fullWidth
          />
        </GridItem>
        <GridItem>
          <AutocompleteInput
            source="driverAccount"
            choices={useDriverAccounts()}
            fullWidth
          />
        </GridItem>
      </Box>
    </GridItem>
  </Grid>
);

export const ItemCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ItemFormFields />
    </SimpleForm>
  </Create>
);
