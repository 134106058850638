import { useState, useEffect } from "react";

const extractUniqueIl = (locationData) => {
  const uniqueIlMap = new Map();
  locationData.forEach((location) => {
    const key = `${location.il_kodu}-${location.il_adi}`;
    if (!uniqueIlMap.has(key)) {
      uniqueIlMap.set(key, {
        il_kodu: location.il_kodu,
        il_adi: location.il_adi,
      });
    }
  });
  return Array.from(uniqueIlMap.values());
};

export const useLocationData = () => {
  const [locationData, setLocationData] = useState([]);
  const [uniqueIl, setUniqueIl] = useState([]);

  useEffect(() => {
    const fetchLocationData = async () => {
      const response = await fetch("/ilceMappings.json");
      const data = await response.json();
      setLocationData(data);

      // Extract unique il values
      const uniqueIlValues = extractUniqueIl(data);
      setUniqueIl(uniqueIlValues);
    };

    fetchLocationData();
  }, []);

  return { locationData, uniqueIl };
};
