import React from "react";
import { Button } from "@mui/material";
import { jsPDF } from "jspdf";
import { useRecordContext } from "react-admin";
import { poppinsRegular } from "../fonts/poppins-regular";
import { poppinsSemiBold } from "../fonts/poppins-semibold";

export const DownloadPdfButton = () => {
  const record = useRecordContext(); // Access the current record
  if (!record) return null;

  const dateStr = record.pickupDate;
  const date = new Date(dateStr);
  const formattedDate = date.toLocaleDateString("en-GB"); // 'en-GB' locale for 'DD/MM/YYYY' format

  const handleDownload = () => {
    const doc = new jsPDF();
    const safeText = (text) => (text ? String(text) : ""); // Function to replace null/undefined with an empty string

    doc.addFileToVFS("Poppins-Regular.ttf", poppinsRegular);
    doc.addFileToVFS("Poppins-SemiBold.ttf", poppinsSemiBold);
    doc.addFont("Poppins-Regular.ttf", "Poppins", "normal");
    doc.addFont("Poppins-SemiBold.ttf", "PoppinsSemiBold", "normal");
    // Set the font for the document
    doc.setFont("PoppinsSemiBold", "normal");

    // Add a header
    doc.setFontSize(18);
    doc.text("IST11 Booking Voucher", 10, 15);
    doc.text("IST11.COM", 160, 15);

    // Add a line under the header
    doc.setLineWidth(0.5);
    doc.line(10, 20, 200, 20);

    // Add Booking Information
    doc.setFontSize(16);
    doc.text("Booking Details", 10, 50);
    doc.setFont("Poppins", "normal");
    doc.setFontSize(14);
    doc.text(`Booking No: ${safeText(record.orderNo)}`, 10, 60);
    doc.text(`Pickup Date: ${safeText(formattedDate)}`, 10, 70);
    doc.text(`Pickup Time: ${safeText(record.pickupTime)}`, 10, 80);

    // Add Passenger Information
    doc.setFont("PoppinsSemiBold", "normal");
    doc.setFontSize(16);
    doc.text("Passenger Information", 10, 100);
    doc.setFont("Poppins", "normal");
    doc.setFontSize(14);
    doc.text(`Name: ${safeText(record.passengerName)}`, 10, 110);
    doc.text(`Phone: ${safeText(record.passengerPhone)}`, 10, 120);

    // Add a section for origin and destination
    doc.setFont("PoppinsSemiBold", "normal");
    doc.setFontSize(16);
    doc.text("Ride Details", 10, 140);
    doc.setFont("Poppins", "normal");
    doc.setFontSize(14);
    doc.text(
      `From: ${safeText(record.origin)} ${safeText(
        record.originAddressDetails
      )}`,
      10,
      150
    );
    doc.text(
      `To: ${safeText(record.destination)} ${safeText(
        record.destinationAddressDetails
      )}`,
      10,
      160
    );
    doc.text(`Flight Number: ${safeText(record.flightNumber)}`, 10, 170);
    doc.text(`Vehicle Type: ${safeText(record.vehicleType)}`, 10, 180);

    // Payment details
    doc.setFont("PoppinsSemiBold", "normal");
    doc.setFontSize(16);
    doc.text("Payment Details", 10, 200);
    doc.setFont("Poppins", "normal");
    doc.setFontSize(14);
    doc.text(
      `Total Amount: ${safeText(record.price)} ${safeText(
        record.priceCurrency
      )}`,
      10,
      210
    );
    doc.text(`Payment Method: ${safeText(record.paymentType)}`, 10, 220);

    // Additional Details
    doc.setFont("PoppinsSemiBold", "normal");
    doc.setFontSize(16);
    doc.text("Additional Details", 10, 240);
    doc.setFont("Poppins", "normal");
    doc.setFontSize(14);
    doc.text(`Payment Method: ${safeText(record.details)}`, 10, 250);

    // Add a line to separate sections
    doc.setLineWidth(0.5);
    doc.line(10, 270, 200, 270);

    // Add a footer with company information
    doc.setFontSize(10);
    doc.text(
      "IST11 TRAVEL TURIZM SAGLIK TICARET LIMITED SIRKETI",
      105,
      280,
      null,
      null,
      "center"
    );
    doc.text(
      "T: +90 530 925 01 64 | info@ist11.com | www.ist11.com | Belge No: 16075",
      105,
      290,
      null,
      null,
      "center"
    );

    doc.save(`Booking_Voucher_${record.orderNo}.pdf`);
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleDownload}
      sx={{ width: "175px", fontSize: "12px" }}
    >
      Download Voucher
    </Button>
  );
};

export default DownloadPdfButton;
