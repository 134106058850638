import React from "react";
import { Admin, Resource } from "react-admin";
import dataProvider from "./dataProvider";
import authProvider from "./authProvider";
import { Navigate } from "react-router-dom";

import { ItemList } from "./components/models/items/itemsList";
import { ItemShow } from "./components/models/items/itemsShow";
import { ItemEdit } from "./components/models/items/itemsEdit";
import { ItemCreate } from "./components/models/items/itemsCreate";
import { UserCreate } from "./components/models/users/usersCreate";
import { UserEdit } from "./components/models/users/usersEdit";
import { UserShow } from "./components/models/users/usersShow";
import { UserList } from "./components/models/users/usersList";

import PeopleIcon from "@mui/icons-material/People";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";

import { customTheme, CustomLayout } from "./components/customTheme";
import { CustomLoginPage } from "./components/customLoginPage";

const App = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={authProvider}
    theme={customTheme}
    layout={CustomLayout}
    loginPage={CustomLoginPage}
  >
    {(permissions) => {
      if (permissions === "admin") {
        return [
          <Resource
            name="orders"
            list={ItemList}
            edit={ItemEdit}
            show={ItemShow}
            create={ItemCreate}
            icon={DirectionsCarIcon}
          />,
          <Resource
            name="users"
            list={UserList}
            show={UserEdit}
            edit={UserShow}
            create={UserCreate}
            icon={PeopleIcon}
          />,
        ];
      } else if (permissions === "editor") {
        return [
          <Resource
            name="orders"
            list={ItemList}
            edit={ItemEdit}
            show={ItemShow}
            create={ItemCreate}
            icon={DirectionsCarIcon}
          />,
        ];
      } else if (permissions === "driver") {
        return [
          <Resource
            name="orders"
            list={ItemList}
            show={ItemShow}
            icon={DirectionsCarIcon}
          />,
        ];
      } else {
        <Navigate to="/login" />;
      }
    }}
  </Admin>
);

export default App;
